<template>
  <article-page :menu="menu">
    <div class="training-content" id="service-order">
      <div class="_subtitle">
        Заказ услуг
      </div>
      <span>
        Для заказа услуги необходимо оставить заявку, в которой нужно указать Ваше имя, контактный номер телефона и часовой пояс,
        по которому Вам удобно разговаривать. При желании можно оставить комментарий к заказу.
      </span>
      <span>
        Открыть диалог с формой заказа можно из раздела
        <accessed-link :name="Tabs.Services" target="_blank">"Услуги"</accessed-link>
        , нажав на кнопку <b>"Заказать тендерные услуги"</b>.
      </span>
      <article-image :src="images[0].src" :alt="images[0].alt"  @click="openViewer(images, 0)"/>
      <span>
        Наш менеджер свяжется с вами в ближайшее время после оформления заказа для согласования стоимости услуги и уточнению дополнительной информации.
      </span>
    </div>
    <div class="training-content" id="service-history">
      <div class="_subtitle">
        История заказанных услуг
      </div>
      <span>
        Все обращения сохраняются и отображаются в разделе
        <accessed-link :name="Tabs.Services" target="_blank">"Заказанные услуги"</accessed-link>.
        После оформления первой заявки, на странице будет отображена таблица с историей Ваших заказов, их стоимостью и статусом выполнения.
      </span>
      <span>
        Вам доступны сортировки по дате оформления заказа и цене, а также фильтры по услуге, номеру закупки и статусу.
      </span>
      <article-image :src="images[1].src" :alt="images[1].alt"  @click="openViewer(images, 1)"/>
    </div>
    <div class="training-content" id="service-list">
      <div class="_subtitle">
        Список услуг
      </div>
      <span>
        Наши специалисты надежно и своевременно оказывают консалтинговые услуги, повышая эффективность и результативность
        наших клиентов – Поставщиков и Заказчиков в сфере участия и проведения всех видов закупок.
      </span>
      <span style="margin-bottom: 12px;">
        Ниже представлен примерный перечень оказываемых услуг. Полный список можно найти в <span class="link" @click="openTenderService(undefined)"> форме заказа</span>.
      </span>
      <service-advertising only-offers />
    </div>
  </article-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useImageViewer } from "@/use/other/useImageViewer";
import { useTenderService } from "@/use/tenderService/useTenderService";
import { Tabs } from "@/router/tabs";
import ArticlePage from "@/components/pages/training/ArticlePage.vue";
import ArticleImage from "@/components/pages/training/ArticleImage.vue";
import ServiceAdvertising from "@/components/pages/services/ServiceAdvertising.vue";
import AccessedLink from "@/components/ui/links/AccessedLink.vue";

export default defineComponent({
  name: "TenderServiceArticle",
  components: {
    AccessedLink,
    ArticlePage,
    ArticleImage,
    ServiceAdvertising,
  },
  setup() {

    const menu = [
      { id: 'service-order', title: 'Заказ услуг' },
      { id: 'service-history', title: 'История заказанных услуг' },
      { id: 'service-list', title: 'Список услуг' },
    ]

    const images = [
      { src: 'orders/new-order.png', alt: 'Заказ тендерной услуги' },
      { src: 'orders/order-history.png', alt: 'История заказанных услуг' },
    ]

    const { openViewer } = useImageViewer();
    const { openTenderService } = useTenderService();

    return {
      menu,
      images,
      Tabs,
      openViewer,
      openTenderService,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/pages/training';
@import '@/assets/styles/mixin/links';

.link {
  @include link-mixin;
}
</style>
